<template>
  <section>
    <v-row>
      <v-col v-if="$can('delete', 'analytics')">
        <v-btn color="primary" to="/analytics/mandv/measures/new">
          <v-icon left>mdi-plus-thick</v-icon>New Measure/Opportunity
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-text-field v-model="search" label="Search" solo></v-text-field>
      </v-col>
    </v-row>
    <validation-summary :messages="server_errors" />
    <confirm-dialog ref="confirmDelete" title="Delete this measure?"></confirm-dialog>

    <v-data-table
      :headers="headers"
      :items="filteredMeasures"      
      class="elevation-1"
      v-model="selectedMappedMeasures"
      v-if="toggleView===1"
      :loading="measuresLoading"
      :loading-text="`Loading... One moment`"
    >

      <template v-slot:[`item.isAssignedToProject`]="{ item }">
        <td>
          <v-btn v-if="item.isAssignedToProject === true" :to="`/analytics/mandv/projects`" icon>
            <v-icon color="green darken-1" :title="getProjectsList(item)"
              >check_circle_outline</v-icon
            >
          </v-btn>
          <v-btn v-else :to="`/analytics/mandv/projects`" icon>
            <v-icon color="primary">login</v-icon>
          </v-btn>
        </td>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <td>
          <v-btn
            :to="`/analytics/mandv/measures/${item.id}`"
            icon
          >
            <v-icon medium>edit</v-icon>
          </v-btn>
          <v-btn icon @click="handleDeleteMeasure(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template>

    </v-data-table>
   </section>
</template>

<script>
import api from "../_api";
import ValidationSummary from "@/components/Dialogs/ValidationSummary";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
export default {
  components: {
    "validation-summary": ValidationSummary,
    "confirm-dialog": ConfirmDialog
  },
  computed: {
    filteredMeasures() {
      const self = this;
      return self.measures.filter(measure => {
        console.log(measure);
        //below will get me bounced from my next interview but it works.....
        return (
          measure.measureName
            .toLowerCase()
            .includes(self.search.toLowerCase()) ||
          measure.measureCode
            .toLowerCase()
            .includes(self.search.toLowerCase()) ||
          measure.measureType.toLowerCase().includes(self.search.toLowerCase())
        );

        //return Object.values(measure).indexOf(self.search) > -1;
      });
    }
  },
  data() {
    return {
      site: { name: "Prentice Day School" },

      headers: [
        { text: "Code", value: "measureCode" },
        { text: "Name", value: "measureName" },
        { text: "Type", value: "measureType" },
        { text: "Assigned To Project", value: "isAssignedToProject" },
        { text: "Actions", value: "id", sortable: false },    
      ],
      server_errors: [],
      mappedMeasures: [],
      measures: [],
      selectedMappedMeasures: [],
      sites: [],
      selectedSites: [],
      search: "",
      toggleView: 1,
      measuresLoading: false
    };
  },
  mounted() {},

  async created() {
    await this.getUserSites();
    await this.getMeasures();
   },

  methods: {
    async getUserSites() {
      let self = this;
      api
        .getSites()
        .then(response => {
          self.sites = response;
        })
        .catch(error => {
          self.server_errors = self.errorSummary(error);
        });
    },

    async getMeasures() {
      let self = this;
      self.measuresLoading = true;
      api
        .getMeasures()
        .then(response => {
          self.measures = response;
          self.measures.forEach((e) => {
            e.isAssignedToProject = e.analtyicProjectResourceModels.length > 0;
          });
        })
        .catch(error => {
          self.server_errors = self.errorSummary(error);
        })
        .finally(() => (self.measuresLoading = false));
    },

    getProjectsList(measure) {
      let list = measure.analtyicProjectResourceModels
        .map((e) => {
          return e.name;
        })
        .join(", ");
      return list;
    },

    filterMeasures() {},

    async handleDeleteMeasure(measure) {
      const msg = `Are you sure you want to delete ${measure.measureName}?  Deleting a measure cannot be undone.`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }

      try {
        await api.deleteMeasure(measure);
        //refresh the collection
        let refreshedMeasures = this.measures.filter(
          (e) => e.id !== measure.id
        );
        this.measures = refreshedMeasures;
      } catch (error) {
        this.$toast.show(error, null, "error");
      }
    },

  }
};
</script>